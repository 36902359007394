.portfolio {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding-bottom: 40px;
}

.portfolio h3 {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 400;
  color: #333333;
}

@media screen and (max-width: 480px) {
  .portfolio h3 {
    text-align: center;
  }
}