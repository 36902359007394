.App {
  background-color: #F4F1F1;
  padding: 20px 12px;
  padding-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .App {
    padding: 40px 80px;
    padding-bottom: 10px;
  }
}